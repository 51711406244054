import { useEffect } from "react";
import { useRouter } from "next/router";
import * as dd from "dingtalk-jsapi";
import { Spin, Modal } from "antd";
import { setCookie } from "nookies";
import { openAuth } from "dingtalk-design-libs/biz/openAuth";
import { useLocalStorageState } from "ahooks";
import { getApiHost } from "@/common/utils";

function IndexPage() {
  const router = useRouter();
  const [loginInfo, setLoginInfo] = useLocalStorageState<string>("loginInfo");

  useEffect(() => {
    const init = async () => {
      // 判断是否在钉钉环境
      const isDingTalk = dd.env.platform !== "notInDingTalk";

      if (!isDingTalk) {
        router.push("/login");
        return;
      }

      // 检查是否有登录信息
      if (Date.now() <= (JSON.parse(loginInfo || "{}").expireTime || 0) && loginInfo) {
        router.push("/coach");
        return;
      }

      setLoginInfo(JSON.stringify({}));
      localStorage.clear();

      // 钉钉环境处理
      try {
        await new Promise((resolve, reject) => {
          dd.ready(() => resolve(null));
          dd.error(reject);
        });
        const corpId = router.query.corpid as string;

        // 获取授权码
        const authRes: any = await openAuth({
          clientId: "suiterjh2dc74daqooyw9", // 应用ID(唯一标识)
          corpId, // 当前组织的corpId
          rpcScope: "Contact.User.Read",
          fieldScope: "Contact.User.mobile",
          type: 0 // 0 标识授权个人信息；1 标识授权组织信息
        });
        console.log(window.location.href, corpId, authRes, "authRes");

        if (!authRes?.result?.authCode) {
          throw new Error("获取授权码失败");
        }

        // 获取用户信息
        const response: any = await fetch(`${getApiHost()}/api/ding/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            corp_id: corpId,
            code: authRes?.result?.authCode
          })
        });

        if (!response.ok) {
          throw new Error("登录接口调用失败");
        }

        const userData = await response.json();
        const expireTime = Date.now() + 86400 * 60 * 1000;
        setLoginInfo(JSON.stringify({ ...(userData.data ?? {}), expireTime }));
        setCookie(null, "token", userData?.data?.token, {
          maxAge: 86400 * 90,
          path: "/"
        });

        router.push("/coach");
      } catch (error) {
        console.error("钉钉授权失败:", error);
        Modal.error({
          title: "授权失败",
          content: "获取钉钉授权信息失败",
          okText: "重试",
          cancelText: "关闭",
          onOk: () => {
            window.location.reload();
          },
          onCancel: async () => {
            // 关闭微应用
            await dd.biz.navigation.close({
              prompt: "授权失败"
            });
          },
          okCancel: true
        });
      }
    };

    init();
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spin size="large" tip="加载中..." />
    </div>
  );
}

export default IndexPage;
